const comBlur = (() => {

  const blurElem = document.querySelectorAll('.js-com-blur');
  if (blurElem.length > 0) {


    for (let i = 0; i < blurElem.length; i++) {

      ScrollTrigger.create({
        trigger: blurElem[i],
        start: "0% 80%",
        toggleClass: { targets: blurElem[i], className: "act" },
        once: true, // 着脱を繰り返したくない場合(一度だけclass付与)
        // markers: true,
      });

    }

    
  }

})();
export default comBlur;