const comParallax = (() => {

  const parallaxElemBox = document.querySelectorAll('.js-parallax-box');
  const parallaxElem = document.querySelectorAll('.js-parallax');
  if (parallaxElemBox.length > 0 && parallaxElem.length > 0) {


    let sW = window.innerWidth;

    let y

    if (sW > 750) {
      y = -100;
    } else {
      y = -50;
    }

    
    for (let i = 0; i < parallaxElemBox.length; i++) {

      gsap.to(parallaxElem[i], {
        y: y,
        ease: "none",
        scrollTrigger: {
          trigger: parallaxElemBox[i],
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0,
          // markers: true
        }
      })

    }


  }

})();
export default comParallax;