const topMainVideo = (which) => {

  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    let jsSecBg = document.getElementById('jsSecBg')
    let videoPc = jsSecBg.querySelector('.js-video');
    let videoSp = jsSecBg.querySelector('.js-video-sp');


    let video
    if (sW > 750) {
      video = videoPc
    } else {
      video = videoSp
    }


    if (which == 'set') {

      video.load()

    }


    if (which == 'start') {

      video.play()

    }



  }

}
export default topMainVideo;