const comScrollAct = (() => {

  const elem = document.querySelectorAll('.js-com-scroll-act');
  if (elem.length > 0) {


    for (let i = 0; i < elem.length; i++) {

      let startPoint1
      let startPoint2

      if (elem[i].classList.contains('point-10') == true) {
        startPoint1 = "0%"
        startPoint2 = "10%"
      } else if (elem[i].classList.contains('point-20') == true) {
        startPoint1 = "0%"
        startPoint2 = "20%"
      } else if (elem[i].classList.contains('point-30') == true) {
        startPoint1 = "0%"
        startPoint2 = "30%"
      } else if (elem[i].classList.contains('point-40') == true) {
        startPoint1 = "0%"
        startPoint2 = "40%"
      } else if (elem[i].classList.contains('point-50') == true) {
        startPoint1 = "0%"
        startPoint2 = "50%"
      } else if (elem[i].classList.contains('point-60') == true) {
        startPoint1 = "0%"
        startPoint2 = "60%"
      } else if (elem[i].classList.contains('point-70') == true) {
        startPoint1 = "0%"
        startPoint2 = "70%"
      } else if (elem[i].classList.contains('point-80') == true) {
        startPoint1 = "0%"
        startPoint2 = "80%"
      } else if (elem[i].classList.contains('point-90') == true) {
        startPoint1 = "0%"
        startPoint2 = "90%"
      } else if (elem[i].classList.contains('point-100') == true) {
        startPoint1 = "0%"
        startPoint2 = "100%"
      } else if (elem[i].classList.contains('point-50-100') == true) {
        startPoint1 = "50%"
        startPoint2 = "100%"
      } else if (elem[i].classList.contains('point-100-100') == true) {
        startPoint1 = "100%"
        startPoint2 = "100%"
      } else if (elem[i].classList.contains('point-c-c') == true) {
        startPoint1 = "50%"
        startPoint2 = "50%"
      } else {
        startPoint1 = "0%"
        startPoint2 = "80%"
      }

      ScrollTrigger.create({
        trigger: elem[i],
        start: `${startPoint1} ${startPoint2}`,
        toggleClass: { targets: elem[i], className: "act-scroll" },
        once: true, // 着脱を繰り返したくない場合(一度だけclass付与)
        // markers: true,
      });

    }


  }

})();
export default comScrollAct;