const bdAccordion = (() => {

  const pageBusinessDetails = (document.getElementById("pageBusinessDetails") != null);
  if (pageBusinessDetails) {

    const jsSecVision = (document.getElementById("jsSecVision") != null);
    if (jsSecVision) {


      let sW = window.innerWidth;
      let sH = window.innerHeight;

      let jsSecVision = document.getElementById("jsSecVision")
      let bgWrap = jsSecVision.querySelector('.js-bg-wrap');
      let btnBox = jsSecVision.querySelector('.js-btn-box');
      let accordionWrap = jsSecVision.querySelector('.js-accordion-wrap');

      let accordionWrapH = accordionWrap.clientHeight
      // console.log(accordionWrapH);
      bgWrap.style.height = `calc(26rem + ${accordionWrapH}px)`
      accordionWrap.style.height = `${0}px`


      btnBox.addEventListener('click', () => {
        if (btnBox.classList.contains('act') == true) {
          btnBox.classList.remove('act');
          // accordionWrap.classList.remove('act');
          accordionWrap.style.height = `${0}px`
        } else {
          btnBox.classList.add('act');
          // accordionWrap.classList.add('act');
          accordionWrap.style.height = `${accordionWrapH}px`
        }
      });





    }

  }

})();
export default bdAccordion;