import {
  funcScrollControl,
  funcLoad,
  topMainVideo,
  comMain,
} from './index';

const funcEntry = (() => {


  funcScrollControl.noScroll();

  const pageTop = document.getElementById('pageTop');

  if (pageTop) {

    topMainVideo('set')

    document.addEventListener("DOMContentLoaded", () => {
      funcLoad()
      setTimeout(() => {
        topMainVideo('start')
      }, 400);
    });

  } else {

    comMain('set')

    document.addEventListener("DOMContentLoaded", () => {
      funcLoad()
      setTimeout(() => {
        comMain('start')
      }, 1500);
    });
  }


})();
export default funcEntry;