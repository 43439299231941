const comFade = (() => {

  const fadeElem = document.querySelectorAll('.js-com-fade');
  if (fadeElem.length > 0) {


    for (let i = 0; i < fadeElem.length; i++) {

      ScrollTrigger.create({
        trigger: fadeElem[i],
        start: "0% 80%",
        toggleClass: { targets: fadeElem[i], className: "act" },
        once: true, // 着脱を繰り返したくない場合(一度だけclass付与)
        // markers: true,
      });

    }


  }

})();
export default comFade;