const comCutBack = (() => {

  const imgs = document.querySelectorAll('.js-cut-back-img');
  const trigger = document.querySelectorAll('.js-com-sec-cut-back-change');
  if (imgs.length > 0 && trigger.length > 0) {


    for (let i = 0; i < imgs.length; i++) {

      // ScrollTrigger.create({
      //   trigger: trigger[i],
      //   start: "0% 100%",
      //   toggleClass: { targets: imgs[i], className: "act" },
      //   // once: true,
      //   // markers: true,
      // });

      let tl = gsap.timeline({
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: trigger[i],
          start: "0% 100%",
          toggleClass: { targets: imgs[i], className: "act" },
          // once: true,
          // markers: true,
        },
      })


      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          // console.log('observer');
          tl.scrollTrigger.refresh()
        });
      });
      observer.observe(target);

    }


  }

})();
export default comCutBack;