const comHeader = (() => {

  const jsHeader = document.getElementById('jsHeader');
  if (jsHeader) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    const jsHeader = document.getElementById("jsHeader");
    // const jsNav = document.getElementById("jsNav");

    if (sW > 750) {

      const pageTop = (document.getElementById("pageTop") != null);
      if (pageTop) {

        jsHeader.classList.add('act-scroll')

        // gsap.to(jsHeader, {
        //   scrollTrigger: {
        //     trigger: 'body',
        //     start: 'top top',
        //     onEnter: () => {
        //       jsHeader.classList.add('act-scroll')
        //     },
        //     onLeaveBack: () => {
        //       jsHeader.classList.remove('act-scroll')
        //     },
        //     // markers: true,
        //   }
        // });

      } else {

        jsHeader.classList.add('act-scroll')
      
      }

    } else {


    }



  }

})()
export default comHeader;