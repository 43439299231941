const comNavSide = (() => {

  const jsNavSide = (document.getElementById("jsNavSide") != null);
  if (jsNavSide) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    let jsNavSide = document.getElementById('jsNavSide');
    let navLists = jsNavSide.querySelectorAll('li a');
    let endElem = document.querySelector('.js-nav-side-end');
    let point = document.querySelectorAll('.js-nav-side-point');


    if (sW > 750) {

      ScrollTrigger.create({
        pin: true,
        trigger: jsNavSide,
        start: '50% 50%',
        endTrigger: endElem,
        end: '0% 100%',
        // markers: true,
      })

      if (point.length > 0) {

        point.forEach((element,index) => {
          
          ScrollTrigger.create({
            trigger: element,
            start: "0% 50%",
            end: "100% 50%",
            toggleClass: { targets: navLists[index], className: "act" },
            // markers: true,
          });

        });
      }

    }



  }

})()
export default comNavSide;