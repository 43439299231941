const funcUserAgent = (() => {



  const ua = new UAParser();
  const body = document.querySelector('body');


  switch (ua.getBrowser().name) {
    case 'Chrome':
      body.classList.add('Chrome');
      break;
    case 'Edge':
      body.classList.add('Edge');
      break;
    case 'Safari':
      body.classList.add('Safari');
      break;
    case 'Firefox':
      body.classList.add('FireFox');
      break;
    case 'Opera':
      body.classList.add('Opera');
      break;
    default:
      body.classList.add('other-browser');
      break;
  }

  switch (ua.getDevice().type) {
    case 'mobile':
      body.classList.add('sp');
      break;
    case 'tablet':
      body.classList.add('tl');
      break;
    default:
      body.classList.add('pc');
      break;
  }

  switch (ua.getOS().name) {
    case 'Android':
      body.classList.add('Android');
      break;
    case 'iOS':
      body.classList.add('iOS');
      break;
    case 'Windows':
      body.classList.add('Windows');
      break;
    case 'macOS':
      body.classList.add('macOS');
      break;
    default:
      //Others
      break;
  }



})();
export default funcUserAgent;