const topCntnt = (() => {

  const secCntnts = document.querySelectorAll('.js-sec-cntnt');
  if (secCntnts.length > 0) {


    let sW = window.innerWidth;
    let sH = window.innerHeight;

    let setStart
    if (sW > 750) {
      setStart = "90% 100%"
    }else{
      setStart = "70% 100%"
    }


    for (let i = 0; i < secCntnts.length; i++) {

      let cntntWrap = secCntnts[i].querySelector('.js-cntnt-wrap');
      let coverBox = secCntnts[i].querySelector('.js-cover-box');
      let grdBox = secCntnts[i].querySelector('.js-grd-box');
      let btn = secCntnts[i].querySelector('.js-btn');
      let ttl = secCntnts[i].querySelectorAll('.js-ttl');


      const ttlColor = (target) => {

        let elem = target.querySelectorAll('span')

        gsap.to(elem, {
          color: '#fff',
          stagger: {
            from: "random",
            each: 0.1,
          }
        });

      }


      let formatElem


      ttl.forEach((element, index) => {
        // console.log(element);
        const target = element;
        const nodes = [...target.childNodes];

        let spanWrapText = ""

        nodes.forEach((node) => {
          if (node.nodeType == 3) {//テキストの場合
            const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
            //spanで囲んで連結
            spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
              return acc + `<span>${v}</span>`
            }, "");
          } else {//テキスト以外
            //<br>などテキスト以外の要素をそのまま連結
            spanWrapText = spanWrapText + node.outerHTML
          }
        })

        target.innerHTML = spanWrapText

        formatElem = ttl[index]
        // console.log(formatElem);

      });


      ScrollTrigger.create({
        trigger: cntntWrap,
        start: setStart,
        onEnter: () => {
          cntntWrap.classList.add('scroll-on')
          setTimeout(() => {
            ttlColor(formatElem)
          }, 1000);
        },
        once: true, // 着脱を繰り返したくない場合(一度だけclass付与)
        // markers: true,
      });


      ScrollTrigger.create({
        trigger: coverBox,
        start: setStart,
        onEnter: () => {
          coverBox.classList.add('scroll-on')
          grdBox.classList.add('scroll-on')
          setTimeout(() => {
            hoverFunc()
          }, 2000);
        },
        once: true, // 着脱を繰り返したくない場合(一度だけclass付与)
        // markers: true,
      });


      const hoverFunc = () => {

        if (sW > 750) {

          let flag = 0

          cntntWrap.addEventListener('mouseover', () => {
            // console.log('mouseover');
            if (flag == 0) {
              flag = 1
              coverBox.classList.add('hover-on')
              grdBox.classList.add('hover-on')
              setTimeout(() => {
                coverBox.classList.remove('hover-on')
                grdBox.classList.remove('hover-on')
              }, 1000);

            }
          });
          cntntWrap.addEventListener('mouseleave', () => {
            // console.log('mouseover');
            flag = 0
          });
        }

      }


    }



  }

})();
export default topCntnt;