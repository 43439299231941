const comFooterBg = (() => {

  const jsFooter = (document.getElementById("jsFooter") != null);
  if (jsFooter) {



    let jsFooter = document.getElementById('jsFooter');
    let bgWrap = jsFooter.querySelector('.js-bg-wrap');

    VANTA.FOG({
      el: bgWrap,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      highlightColor: 0x060d96,
      midtoneColor: 0x060d96,
      lowlightColor: 0x0065ad,
      baseColor: 0x0065ad,
      blurFactor: 0.90,
      speed: 3.00,
      zoom: 0.20
    })



  }

})();
export default comFooterBg;