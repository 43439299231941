const bdTab = (() => {

  const pageBusinessDetails = (document.getElementById("pageBusinessDetails") != null);
  if (pageBusinessDetails) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    let jsSecTab = document.getElementById("jsSecTab")
    let tabWrap = jsSecTab.querySelector('.js-tab-wrap');
    let jsHeader = document.getElementById("jsHeader")

    let jsHeaderH = jsHeader.clientHeight


    if (sW > 750) {
    } else {
      // console.log(tabWrap);
      ScrollTrigger.create({
        pin: true,
        pinSpacing: false,
        trigger: tabWrap,
        start: `0% ${jsHeaderH}px`,
        endTrigger: 'body',
        end: '100% 0%',
        onEnter: () => {
          // console.log('onEnter');
          tabWrap.classList.add('act')
        },
        // onEnterBack: () => {
        //   console.log('onEnterBack');
        // },
        // onLeave: () => {
        //   console.log('onLeave');
        // },
        onLeaveBack: () => {
          // console.log('onLeaveBack');
          tabWrap.classList.remove('act')
        },
        // markers: true,
      })

    }



  }

})();
export default bdTab;