const comMain = (which) => {

  const jsComSecMain = (document.getElementById("jsComSecMain") != null);
  if (jsComSecMain) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    let jsComSecMain = document.getElementById('jsComSecMain');
    let coverBox = jsComSecMain.querySelector('.js-cover-box');
    let grdBox = jsComSecMain.querySelector('.js-grd-box');
    let copyBox = jsComSecMain.querySelector('.js-copy-box');
    let mvEndFades = document.querySelectorAll('.js-mv-end-fade');

    let hash = location.hash
    // console.log(hash);


    if (which == 'set') {
      jsComSecMain.style.height = `${sH}px`

      if (hash) {
        coverBox.classList.add('act')
        copyBox.classList.add('act')
        mvEndFades.forEach(element => {
          element.classList.add('act')
        });
      }
    }


    if (which == 'start') {

      if (hash) {

      } else {

        coverBox.classList.add('act')
        grdBox.classList.add('act')
        setTimeout(() => {
          copyBox.classList.add('act')
          mvEndFades.forEach(element => {
            element.classList.add('act')
          });
        }, 1000);

      }

    }



  }

}
export default comMain;