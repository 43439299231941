import {
  funcScrollControl,
} from './index';

const bdFlow = (() => {

  const pageBusinessDetails = (document.getElementById("pageBusinessDetails") != null);
  if (pageBusinessDetails) {

    const jsSecFlow = (document.getElementById("jsSecFlow") != null);
    const jsSecFlowModal = (document.getElementById("jsSecFlowModal") != null);
    if (jsSecFlow && jsSecFlowModal) {


      let sW = window.innerWidth;
      let sH = window.innerHeight;


      if (sW > 750) {

        let jsSecFlow = document.getElementById("jsSecFlow")
        let jsSecFlowModal = document.getElementById("jsSecFlowModal")
        let btns = jsSecFlow.querySelectorAll('.js-btn');
        let wraps = jsSecFlowModal.querySelectorAll('.js-modal-wrap');

        btns.forEach((element, index) => {

          element.addEventListener('mouseover', () => {
            funcScrollControl.noScroll();
            wraps[index].classList.add('act')
            wraps[index].classList.remove('no-act')
          });

          element.addEventListener('mouseleave', () => {
            funcScrollControl.returnScroll();
            wraps[index].classList.remove('act')
            wraps[index].classList.add('no-act')
          });

          wraps[index].addEventListener('mouseover', () => {
            funcScrollControl.noScroll();
            wraps[index].classList.add('act')
            wraps[index].classList.remove('no-act')
          });

          wraps[index].addEventListener('mouseleave', () => {
            funcScrollControl.returnScroll();
            wraps[index].classList.remove('act')
            wraps[index].classList.add('no-act')
          });

        });

      } else {

        MicroModal.init({
          disableScroll: true,
          awaitOpenAnimation: true,
          awaitCloseAnimation: true,
          // onShow: modal => console.info(`${modal.id} is shown`), // [1]
          // onClose: modal => console.info(`${modal.id} is hidden`), // [2]
        });

      }



    }

  }

})();
export default bdFlow;